import React, {useEffect, useState} from "react";
import logo from "../images/immobili.jpg";
import "../App.css";
import Navbar from "../components/navbar/Navbar";
import "./immobili.css"

function Immobili() {
    const [json, setJson] = useState<any[]>([])
    /*
    React.useEffect(() => {
        fetch("/api")
            .then((res) => res.json())
            .then((data) => setJson(data.message))
    }, []);
    */

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    // @ts-ignore
    return (
        <div className="App">
            <Navbar></Navbar>
            <div className={matches ? "container" : "containerMin"}>
                <img src={logo} className={matches ? "immobiliimage" : "immobiliimageMin"} />
                <div className="centered"><h1>IMMOBILI</h1></div>
            </div>
            <div className="content">
                <div className={matches ? "contactsContainer" : "contactsContainerMin"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    <a href={"https://www.immobiliare.it/annunci/106377437/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1411313005/cover-m-c.jpg"}/>
                            <a><h4>{"Appartamento viale Felsina"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 650000"}</p></a>
                        </div>
                    </a>
                    <a href={"https://www.immobiliare.it/annunci/104488223/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1365993375/cover-m-c.jpg"}/>
                            <a><h4>{"Trilocale via Giuseppe Rivani 87"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 300000"}</p></a>
                        </div>
                    </a>
                    <a href={"https://www.immobiliare.it/annunci/108379293/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1454724343/cover-m-c.jpg"}/>
                            <a><h4>{"Appartamento viale Repubblica"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 370000"}</p></a>
                        </div>
                    </a>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                <div className={matches ? "contactsContainer" : "contactsContainerMin"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    <a href={"https://www.immobiliare.it/annunci/109224963/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1471161731/cover-m-c.jpg"}/>
                            <a><h4>{"Trilocale via De Giovanni"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 180000"}</p></a>
                        </div>
                    </a>
                    <a href={"https://www.immobiliare.it/annunci/107279435/#mappa"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1432420767/cover-m-c.jpg"}/>
                            <a><h4>{"Villa via Poma 20, San Lazzaro"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 770000"}</p></a>
                        </div>
                    </a>
                    <a href={"https://www.immobiliare.it/annunci/107343105/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1433564633/cover-m-c.jpg"}/>
                            <a><h4>{"Appartamento via Spartaco"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 390000"}</p></a>
                        </div>
                    </a>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                <div className={matches ? "contactsContainer" : "contactsContainerMin"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    <a href={"https://www.immobiliare.it/annunci/91657974/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                        <div className="container"><div className="venduto">VENDUTO</div>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1127665060/m-c.jpg"}/>
                            </div>
                            <a><h4>{"Via 25 Aprile 1945 3, San Lazzaro"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 1150000"}</p></a>
                        </div>
                    </a>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                {
                
                /* Un solo immobile
                <div className={matches ? "contactsContainer" : "contactsContainerMin"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    <a href={"https://www.immobiliare.it/annunci/91657974/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1127665060/m-c.jpg"}/>
                            <a><h4>{"Via 25 Aprile 1945 3, San Lazzaro"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 1150000"}</p></a>
                        </div>
                    </a>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                */}
                {/*
                <div className={matches ? "contactsContainer" : "contactsContainerMin"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>

                    <a href={"https://www.immobiliare.it/annunci/91657974/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1127665060/m-c.jpg"}/>
                            <a><h4>{"Via 25 Aprile 1945 3, San Lazzaro"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: su richiesta"}</p></a>
                        </div>
                    </a>
                    <a href={"https://www.immobiliare.it/annunci/96593710/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1192812134/cover-m-c.jpg"}/>
                            <a><h4>{"Rifugio via Pozza 2, Monterenzio"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 99000"}</p></a>
                        </div>
                    </a>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                */}


                <div className={"contactsContainer"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    {json && json.map((item, index) =>
                        {return index < 3 ?
                            <a href={item.link}>
                                <div className={"contactsBoxImm"}>
                                    <img className={"houseImage"} src={item.image}/>
                                    <h4>{item.via}</h4>
                                    <a><p>{item.tipo}</p></a>
                                </div>
                            </a> :
                            <div></div>
                        })
                    }
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                <div className={"contactsContainer"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    {json && json.map((item, index) =>
                    {return index >= 3 ?
                        <a href={item.link}>
                            <div className={"contactsBoxImm"}>
                                <img className={"houseImage"} src={item.image}/>
                                <h4>{item.via}</h4>
                                <a><p>{item.tipo}</p></a>
                            </div>
                        </a> :
                        <div></div>
                    })
                    }
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
            </div>
            <footer className={matches ? "footerImm" : "footerImmMin"}>
                <p>© Lorena Ceccacci immobiliare, All Rights Reserved</p>
            </footer>
        </div>
    );
}

export default Immobili;

